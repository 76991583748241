import { useTeamsPermissionsService } from '@atlassian/teams-common/use-teams-permissions';

import { type PeopleAndTeamsConfigurationContextType, PeopleMenuOverrides } from '../../types';

export type TeamPermissions = {
	canCreateTeams: boolean;
	canViewTeams: boolean;
};

export const usePeopleAndTeamsConfigurationOverrides = (
	teamPermissionCheckEnabled: boolean,
	orgId?: string,
	teamPermissionsProps?: TeamPermissions,
): Record<keyof PeopleAndTeamsConfigurationContextType, string[]> => {
	const { permissions: teamPermissionsFromHook } = useTeamsPermissionsService(orgId, {
		enabled: teamPermissionCheckEnabled && !teamPermissionsProps,
	});

	const teamPermissions = teamPermissionsProps || teamPermissionsFromHook;

	return {
		'people-menu': [
			...(!teamPermissions?.canCreateTeams ? [PeopleMenuOverrides.createTeamsDisabled] : []),

			...(!teamPermissions?.canViewTeams ? [PeopleMenuOverrides.viewTeamsDisabled] : []),
		],
	};
};
