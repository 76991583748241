import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import { AbstractCompanyHubItem } from '@confluence/side-navigation/entry-points/AbstractCompanyHubItem';
import { SPAViewContext } from '@confluence/spa-view-context';

import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	companyHubLink: {
		id: 'app-navigation.company-hub.link',
		description: 'name of the fallback name to the company hub page',
		defaultMessage: 'Company hub',
	},
});

/* Revisit styles when transition from site title to space name is done https://hello.jira.atlassian.cloud/browse/CTE-3671 */
const companyHubItemStyles = xcss({
	display: 'none',
	[media.above.md]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
const highlightStyles = (isHighlightedBorderColor: any, isHighlighted: boolean) =>
	xcss({
		borderTopColor: 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderBottomColor: isHighlighted ? `${isHighlightedBorderColor}` : 'transparent',
		borderBlockWidth: 'border.width.indicator',
		borderBlockStyle: 'solid',
	});

export const CompanyHubItem = () => {
	const { frontCoverState, loading } = useContext(SPAViewContext);
	const { theme } = useContext(AppNavigationContext);

	if (loading || !frontCoverState) {
		return null;
	}

	const isHighlightedBorderColor = theme?.mode.primaryButton.selected.borderColor;

	return (
		<AbstractCompanyHubItem navVersion="3">
			{({ companyHubName, isSelected, namedRoute, onClick }) => (
				<Box
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
					xcss={[companyHubItemStyles, highlightStyles(isHighlightedBorderColor, isSelected)]}
					{...(isSelected && { testId: 'highlightContainer' })}
				>
					<PrimaryItem
						testId="app-navigation-company-hub-item"
						isHighlighted={false}
						label={companyHubName || <FormattedMessage {...i18n.companyHubLink} />}
						namedRoute={namedRoute}
						onClick={onClick}
					/>
				</Box>
			)}
		</AbstractCompanyHubItem>
	);
};
