import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getTokenValue, token, useThemeObserver } from '@atlaskit/tokens';
import { atlassianTheme, generateTheme } from '@atlaskit/atlassian-navigation';

import { reduceLightness, getLightnessPercentage } from '@confluence/color-utils';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { getSavedThemeLocal } from '@confluence/theming/entry-points/getSavedThemeLocal';

import { AppNavigationUnifiedQuery } from '../AppNavigationUnifiedQuery.graphql';
import type { AppNavigationUnifiedQuery as AppNavigationUnifiedQueryType } from '../__types__/AppNavigationUnifiedQuery';

const ADG3_BACKGROUND_TOKENIZED = token('elevation.surface', '#FFFFFF');
const ADG3_HIGHLIGHT_TOKENIZED = token('color.text.brand', '#0052CC');

const BACKGROUND_LIGHTNESS_MAX = 35;

const getCSSVariableValue = (variableExpression: string) => {
	const matcher = variableExpression.match(/var\(([^,\)]+)(,.*)?\)/);
	if (matcher) {
		const variable = matcher[1].trim();
		const fallback = matcher[2] ? matcher[2].replace(',', '').trim() : '';
		if (typeof document === 'undefined') return fallback;
		const value = window
			.getComputedStyle(document.documentElement)
			.getPropertyValue(variable)
			.trim();

		return value || fallback;
	}

	return '';
};

const isValidColor = (color: string) =>
	color.startsWith('#') ||
	color.startsWith('rgb(') ||
	color.startsWith('rgba(') ||
	color.startsWith('hsl(') ||
	color.startsWith('hsla(');

const formatColor = (color: string) => {
	if (color.startsWith('#')) {
		const formattedHex = color.trim().toUpperCase();
		if (formattedHex.length === 4) {
			const [c1, c2, c3] = formattedHex.substring(1, 4);
			return `#${c1}${c1}${c2}${c2}${c3}${c3}`;
		}
		return formattedHex;
	} else if (color.startsWith('rgba(') && color.split(',').length === 4) {
		return `rgb(${color.substring(5, color.lastIndexOf(','))})`;
	} else if (color.startsWith('var(')) {
		return formatColor(getCSSVariableValue(color)); // Will only get re-calculated if the variable is from ADS
	}
	return color;
};

const getDarkModeBackgroundColor = (currentBackgroundColor: string): string => {
	if (currentBackgroundColor.toLowerCase() === '#ffffff') {
		// getTokenValue doesn't work in SSR thus hardcode fallback to current surface value, fix in: https://product-fabric.atlassian.net/browse/DSP-9781
		return formatColor(getTokenValue('elevation.surface', '#1d2125'));
	} else {
		const lightnessPercentage = getLightnessPercentage(currentBackgroundColor);
		if (lightnessPercentage === undefined) {
			return currentBackgroundColor;
		}
		return lightnessPercentage > BACKGROUND_LIGHTNESS_MAX
			? reduceLightness(currentBackgroundColor, BACKGROUND_LIGHTNESS_MAX)
			: currentBackgroundColor;
	}
};

const generateValidTheme = (
	shouldEvaluateBackgroundLightness: boolean,
	data?: AppNavigationUnifiedQueryType,
) => {
	const backgroundColor = formatColor(
		data?.lookAndFeel?.custom?.horizontalHeader?.backgroundColor || '',
	);
	const highlightColor = formatColor(
		data?.lookAndFeel?.custom?.horizontalHeader?.primaryNavigation?.highlightColor || '',
	);

	// Look-and-Feel without token/var matches raw token value (themed or fallback)
	if (
		backgroundColor === formatColor(getTokenValue('elevation.surface', '#FFFFFF')) &&
		highlightColor === formatColor(getTokenValue('color.text.brand', '#0052CC'))
	) {
		return atlassianTheme;
	}

	// Look-and-Feel uses token/var and matches tokenized default
	if (
		backgroundColor === ADG3_BACKGROUND_TOKENIZED &&
		highlightColor === ADG3_HIGHLIGHT_TOKENIZED
	) {
		return atlassianTheme;
	}

	if (!isValidColor(backgroundColor) || !isValidColor(highlightColor)) {
		return atlassianTheme;
	}

	/* saving hsla colors result in a Type Error that occurs within generateTheme
	 * generateTheme calls on generateTextColor, which then errors out at call to chromatism.contrastRatio
	 * this is because hsla color code is not accepted in chromatism library.
	 * surrounding generateTheme with a try and catch to return the default Atlassian background/highlight colors on error
	 */
	try {
		return generateTheme({
			name: 'theme',
			backgroundColor: shouldEvaluateBackgroundLightness
				? getDarkModeBackgroundColor(backgroundColor)
				: backgroundColor,
			highlightColor,
		});
	} catch (e) {
		return atlassianTheme;
	}
};

export const useNavigationTheme = () => {
	const { data, loading, error } =
		useQuery<AppNavigationUnifiedQueryType>(AppNavigationUnifiedQuery);

	// Using hook and getTokenValue() vs token() because of equality comparison used in generateValidTheme()
	//    The hook allows these variables to re-compute when the theme is changed.
	//    Even when the look-and-feel defaults are tokenized, if the site has configured and reverted back to the original
	//      colors, if they aren't using the css var w/ tokens, the value here would not match if just using token()
	const adsTheme = useThemeObserver();

	if (error && isUnauthorizedError(error)) {
		markErrorAsHandled(error);
	}

	// Use locally stored theme for SSR because `useThemeObserver()` won't work in SSR
	//  also, "auto" won't work in SSR if resolves to "dark" because we cannot get the system preference off the window
	//  see https://product-fabric.atlassian.net/browse/DSP-9781
	const effectiveTheme = process.env.REACT_SSR ? getSavedThemeLocal() : adsTheme;
	const shouldEvaluateBackgroundLightness = effectiveTheme?.colorMode === 'dark';

	// Dependent on adsTheme so theme can re-calculate
	const theme = useMemo(() => {
		return loading ? atlassianTheme : generateValidTheme(shouldEvaluateBackgroundLightness, data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, data, adsTheme]);

	return { loading, theme };
};
