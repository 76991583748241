export const SPACE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-spaces-link';
export const PEOPLE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-people-link';

export const ATLASSIAN_SWITCHER_UFO_ENABLED = 'confluence.frontend.atlassian-switcher.enable-ufo';

export const OPEN_SITES_PERMISSION_CHECK_FLAG =
	'confluence.frontend.teams.enable-open-sites-permission-check';

export const TEAM_PRODUCT_ACCESS_EXPERIMENT = 'confluence.frontend.growth.team-product-access';
