import { useMemo } from 'react';

import { useFlightDeckArtifact } from '@atlassiansox/flight-deck-frontend-client';

import { type PeopleAndTeamsMenuConfiguration } from '../packages/people-menu/types';
import {
	type PeopleAndTeamsConfigurationContextType,
	type PeopleAndTeamsConfigurationFlightDeckType,
} from '../types';

import { FD_CLIENT_FETCH_PROPS } from './constants';

const transformArtifactsWithOverrides = (
	artifact: PeopleAndTeamsMenuConfiguration | null,
	overrideKeys: string[],
) => {
	if (!artifact?.overrides?.length || overrideKeys.length === 0) {
		return artifact;
	}

	return overrideKeys.reduce<PeopleAndTeamsMenuConfiguration>((acc, overrideKey) => {
		return {
			...acc,
			...((artifact.overrides &&
				artifact.overrides.find((override) => override.key === overrideKey)?.item) ||
				null),
		};
	}, artifact);
};

export const usePeopleAndTeamsRemoteArtifact = (
	product: string,
	locale?: string,
	overridesKeys?: Record<keyof PeopleAndTeamsConfigurationContextType, string[]>,
) => {
	const dimensions = useMemo(
		() => ({
			...FD_CLIENT_FETCH_PROPS.dimensions,
			locale,
		}),
		[locale],
	);

	const artifact = useFlightDeckArtifact<PeopleAndTeamsConfigurationFlightDeckType>(
		FD_CLIENT_FETCH_PROPS.name,
		dimensions,
	);

	/**
	 * FlightDeck doesn't allow custom, a 'product', dimension so we must handle this ourselves.
	 * We can probably remove this memo once that functionality is available
	 */
	return useMemo<PeopleAndTeamsConfigurationContextType>(() => {
		const productArtifact = artifact
			? product in artifact['people-menu']
				? artifact['people-menu'][product]
				: artifact['people-menu'].default
			: null;

		return {
			['people-menu']: transformArtifactsWithOverrides(
				productArtifact,
				overridesKeys ? overridesKeys['people-menu'] : [],
			),
		};
	}, [artifact, product, overridesKeys]);
};
