import memoizeOne from 'memoize-one';

import { cfetch } from '@confluence/network';

export const getPersonalizationSiteUserTraits = async (
	cloudId: string,
	atlassianAccountId: string,
) => {
	try {
		const response = await cfetch(
			`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${atlassianAccountId}`,
		);
		return response.json();
	} catch (error: any) {
		return error;
	}
};

export const memoizedGetPersonalizationSiteUserTraits = memoizeOne(
	getPersonalizationSiteUserTraits,
);
