import React from 'react';

import { type PeopleAndTeamsConfigurationContextType } from '../../types';
import { usePeopleAndTeamsConfigurationContext } from '../main';

type NonNullableConfigurationConfig<
	ConfigKey extends keyof PeopleAndTeamsConfigurationContextType,
> = NonNullable<PeopleAndTeamsConfigurationContextType[ConfigKey]>;

export interface WithMandatoryPeopleAndTeamsConfigurationProps<
	ConfigKey extends keyof PeopleAndTeamsConfigurationContextType,
> {
	remoteConfig: NonNullableConfigurationConfig<ConfigKey>;
}

function isMandatoryPeopleAndTeamsConfiguration<
	ConfigKey extends keyof PeopleAndTeamsConfigurationContextType,
>(
	context: PeopleAndTeamsConfigurationContextType[ConfigKey],
): context is NonNullableConfigurationConfig<ConfigKey> {
	return Boolean(context);
}

export const withMandatoryPeopleAndTeamsConfiguration =
	<ConfigKey extends keyof PeopleAndTeamsConfigurationContextType, Props>(
		WrappedComponent: React.ComponentType<
			Props & WithMandatoryPeopleAndTeamsConfigurationProps<ConfigKey>
		>,
		key: ConfigKey,
	) =>
	(props: Props) => {
		const remoteConfig = usePeopleAndTeamsConfigurationContext();
		if (!remoteConfig) {
			return null;
		}

		const keyedConfig = remoteConfig[key];
		if (!isMandatoryPeopleAndTeamsConfiguration<ConfigKey>(keyedConfig)) {
			return null;
		}

		return <WrappedComponent remoteConfig={keyedConfig} {...props} />;
	};
