import React, { createContext, type ReactNode } from 'react';

import {
	FlightDeckProvider,
	type FlightDeckProviderProps,
} from '@atlassiansox/flight-deck-frontend-client';

import { usePeopleAndTeamsRemoteArtifact } from '../flight-deck';
import { type PeopleAndTeamsConfigurationContextType } from '../types';

import { DEFAULT_CONTEXT } from './constants';
import {
	type TeamPermissions,
	usePeopleAndTeamsConfigurationOverrides,
} from './hooks/usePeopleAndTeamsConfigurationOverrides';

export const PeopleAndTeamsConfigurationContext =
	createContext<PeopleAndTeamsConfigurationContextType>(DEFAULT_CONTEXT);

export interface PeopleTeamsConfigurationProviderProps {
	product: string;
	children: ReactNode;
	teamPermissions?: TeamPermissions;
	locale?: string;
	/**
	 * @deprecated use teamPermissions instead - calculate permissions using: import { useTeamsPermissionsService } from '@atlassian/teams-common/use-teams-permissions';
	 */
	teamPermissionCheckEnabled?: boolean;
	/**
	 * @deprecated use teamPermissions instead - calculate permissions using: import { useTeamsPermissionsService } from '@atlassian/teams-common/use-teams-permissions';
	 */
	orgId?: string;
}

export const PeopleAndTeamsBasicConfigurationProvider = ({
	children,
	locale,
	product,
	teamPermissions,
	orgId,
	teamPermissionCheckEnabled = false,
}: PeopleTeamsConfigurationProviderProps) => {
	const overrides = usePeopleAndTeamsConfigurationOverrides(
		teamPermissionCheckEnabled,
		orgId,
		teamPermissions,
	);
	const artifact = usePeopleAndTeamsRemoteArtifact(product, locale, overrides);

	return (
		<PeopleAndTeamsConfigurationContext.Provider value={artifact || DEFAULT_CONTEXT}>
			{children}
		</PeopleAndTeamsConfigurationContext.Provider>
	);
};

export const PeopleAndTeamsConfigurationProvider = ({
	children,
	locale,
	product,
	teamPermissions,
	teamPermissionCheckEnabled,
	orgId,
	...flightDeckProps
}: PeopleTeamsConfigurationProviderProps & FlightDeckProviderProps) => {
	return (
		<FlightDeckProvider product={product} {...flightDeckProps}>
			<PeopleAndTeamsBasicConfigurationProvider
				locale={locale}
				product={product}
				teamPermissions={teamPermissions}
				teamPermissionCheckEnabled={teamPermissionCheckEnabled}
				orgId={orgId}
			>
				{children}
			</PeopleAndTeamsBasicConfigurationProvider>
		</FlightDeckProvider>
	);
};

export const usePeopleAndTeamsConfigurationContext = () =>
	React.useContext(PeopleAndTeamsConfigurationContext);
