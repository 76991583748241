// Renotify Experiment traits

// Batch traits
export const CONFLUENCE_SITE_WITH_INACTIVE_INVITED_USER_ELIGIBLE_FOR_REMINDER_TRAIT =
	'confluence_site_with_inactive_invited_user_eligible_for_reminder';

// Realtime traits
export const CONFLUENCE_UI_CLICKED_BUTTON_REINVITE_RENOTIFY_LAST_TRAIT =
	'confluence_ui_clicked_button_reinvite_renotify_last';
export const CONFLUENCE_UI_CLICKED_BUTTON_DISMISS_RENOTIFY_FIRST_TRAIT =
	'confluence_ui_clicked_button_dismiss_renotify_first';
