import { type PeopleAndTeamsMenuConfiguration } from './packages/people-menu/types';

export enum PeopleMenuOverrides {
	createTeamsDisabled = 'create-teams-disabled',
	viewTeamsDisabled = 'view-teams-disabled',
}

export type PeopleMenuProductDimensions =
	| 'atlas'
	| 'compass'
	| 'confluence'
	| 'default'
	| 'jira'
	| string;

export type PeopleMenuProductConfiguration = Record<
	PeopleMenuProductDimensions,
	PeopleAndTeamsMenuConfiguration
>;

export interface PeopleAndTeamsConfigurationFlightDeckType {
	'people-menu': PeopleMenuProductConfiguration;
}

export interface PeopleAndTeamsConfigurationContextType {
	'people-menu': PeopleAndTeamsMenuConfiguration | null;
}
