/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivitiesObjectType {
  BLOGPOST = "BLOGPOST",
  DATABASE = "DATABASE",
  EMBED = "EMBED",
  ISSUE = "ISSUE",
  PAGE = "PAGE",
  WHITEBOARD = "WHITEBOARD",
}

export enum ActivityEventType {
  ASSIGNED = "ASSIGNED",
  COMMENTED = "COMMENTED",
  CREATED = "CREATED",
  EDITED = "EDITED",
  LIKED = "LIKED",
  PUBLISHED = "PUBLISHED",
  TRANSITIONED = "TRANSITIONED",
  UNASSIGNED = "UNASSIGNED",
  UPDATED = "UPDATED",
  VIEWED = "VIEWED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecentlyWorkedOnQuery
// ====================================================

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_events {
  eventType: ActivityEventType | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_favourited {
  isFavourite: boolean | null;
  favouritedDate: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_lastcontributed {
  status: string | null;
  when: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_scheduled {
  isScheduled: boolean | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser {
  favourited: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_favourited | null;
  lastcontributed: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_lastcontributed | null;
  scheduled: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser_scheduled | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata {
  currentuser: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata_currentuser | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_links {
  webui: string | null;
  editui: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_editorVersion_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_editorVersion {
  nodes: (RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_editorVersion_nodes | null)[] | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_space {
  id: string | null;
  key: string | null;
  name: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_emoji {
  nodes: (RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes | null)[] | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content {
  id: string | null;
  type: string | null;
  subType: string | null;
  title: string | null;
  status: string | null;
  blank: boolean;
  metadata: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_metadata;
  links: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_links | null;
  editorVersion: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_editorVersion | null;
  space: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_space | null;
  emoji: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content_emoji | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object {
  id: string;
  events: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_events[] | null;
  content: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object_content | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges_node {
  id: string;
  timestamp: string | null;
  object: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node_object | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_edges {
  cursor: string;
  node: RecentlyWorkedOnQuery_activities_myActivities_all_edges_node | null;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all_pageInfo {
  hasNextPage: boolean;
}

export interface RecentlyWorkedOnQuery_activities_myActivities_all {
  edges: (RecentlyWorkedOnQuery_activities_myActivities_all_edges | null)[] | null;
  pageInfo: RecentlyWorkedOnQuery_activities_myActivities_all_pageInfo;
}

export interface RecentlyWorkedOnQuery_activities_myActivities {
  all: RecentlyWorkedOnQuery_activities_myActivities_all | null;
}

export interface RecentlyWorkedOnQuery_activities {
  myActivities: RecentlyWorkedOnQuery_activities_myActivities | null;
}

export interface RecentlyWorkedOnQuery {
  activities: RecentlyWorkedOnQuery_activities | null;
}

export interface RecentlyWorkedOnQueryVariables {
  cloudId: string;
  first?: number | null;
  after?: string | null;
  eventTypes?: ActivityEventType[] | null;
  objectTypes?: ActivitiesObjectType[] | null;
}
