import gql from 'graphql-tag';

const PageContentFragment = gql`
	fragment PageContentFragment on Content {
		id
		type
		subType
		title
		status
		blank
		metadata {
			currentuser {
				favourited {
					isFavourite
					favouritedDate
				}
				lastcontributed {
					status
					when
				}
				scheduled {
					isScheduled
				}
			}
		}
		links {
			webui
			editui
		}
		editorVersion: properties(key: "editor") {
			nodes {
				id
				key
				value
			}
		}
		space {
			id
			key
			name
		}
		emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
			nodes {
				id
				key
				value
			}
		}
	}
`;

const ActivitiesConnectionFragment = gql`
	fragment ActivitiesConnectionFragment on ActivitiesConnection {
		edges {
			cursor
			node {
				id
				timestamp
				object {
					id
					events {
						eventType
					}
					content {
						...PageContentFragment
						status
						blank
						emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
							nodes {
								id
								key
								value
							}
						}
					}
				}
			}
		}
		pageInfo {
			hasNextPage
		}
	}
	${PageContentFragment}
`;

export const RecentlyViewedQuery = gql`
	query RecentlyViewedQuery(
		$cloudId: ID!
		$first: Int
		$after: String
		$eventTypes: [ActivityEventType!]
		$objectTypes: [ActivitiesObjectType!]
	) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: $objectTypes
								cloudIds: [$cloudId]
								eventTypes: $eventTypes
							}
						}
					]
					first: $first
					after: $after
				) {
					...ActivitiesConnectionFragment
				}
			}
		}
	}
	${ActivitiesConnectionFragment}
`;

export const RecentlyWorkedOnQuery = gql`
	query RecentlyWorkedOnQuery(
		$cloudId: ID!
		$first: Int
		$after: String
		$eventTypes: [ActivityEventType!]
		$objectTypes: [ActivitiesObjectType!]
	) {
		activities {
			myActivities {
				all(
					filters: [
						{
							type: AND
							arguments: {
								products: [CONFLUENCE]
								objectTypes: $objectTypes
								cloudIds: [$cloudId]
								eventTypes: $eventTypes
							}
						}
					]
					first: $first
					after: $after
				) {
					...ActivitiesConnectionFragment
				}
			}
		}
	}
	${ActivitiesConnectionFragment}
`;

export const RecentlyCreatedByMeQuery = gql`
	query RecentlyCreatedByMeQuery(
		$cql: String!
		$cqlcontext: String!
		$first: Int
		$after: String
		$includeAlias: Boolean = false
	) {
		search(cql: $cql, cqlcontext: $cqlcontext, first: $first, after: $after) {
			nodes {
				url
				content {
					...PageContentFragment
					space {
						alias @include(if: $includeAlias)
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${PageContentFragment}
`;

export const RecentlyDraftedQuery = gql`
	query RecentlyDraftedQuery(
		$first: Int
		$after: String
		$context: String
		$includeAlias: Boolean = false
	) {
		search(
			cql: "type in (page,blogpost) order by lastModified desc"
			cqlcontext: $context
			first: $first
			after: $after
		) {
			nodes {
				entityType
				url
				content {
					...PageContentFragment
					space {
						alias @include(if: $includeAlias)
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${PageContentFragment}
`;

export const RecentlyStarredQuery = gql`
	query RecentlyStarredQuery($limit: Int, $start: Int) {
		favoriteContent(limit: $limit, start: $start) {
			nodes {
				...PageContentFragment
			}
			pageInfo {
				hasNextPage
			}
		}
	}
	${PageContentFragment}
`;
