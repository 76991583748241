import { useMemo } from 'react';

import type { PeopleMenuProps } from '@atlassian/people-menu';

export type ProjectTeamAccessData = NonNullable<
	PeopleMenuProps['teamCreateDialogProps']
>['projectTeamAccess'];

export const useTPAExperiment = (): ProjectTeamAccessData => {
	return useMemo(() => {
		return {
			enabled: false,
			reason: '',
			growthHoldoutCohort: 'control',
			fireFeatureExposed: () => {},
		};
	}, []);
};
