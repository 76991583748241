import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { Link } from '@confluence/route-manager';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavItemWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UnstyledLinkBase = styled(Link)({
	display: 'flex',
	alignItems: 'center',
	color: 'inherit',
	'&:hover, &:focus, &:active, &:visited': {
		textDecoration: 'none',
		color: 'inherit',
		outline: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UnstyledPopupLink = styled(UnstyledLinkBase)({
	justifyContent: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HomeLogo = styled.div({
	outline: 'none',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PrimaryItemWrapper = styled.div({
	color: token('color.text', N800),
	display: 'flex',
});
