import { type ComponentType } from 'react';

import {
	usePeopleAndTeamsConfigurationContext,
	withMandatoryPeopleAndTeamsConfiguration,
	type WithMandatoryPeopleAndTeamsConfigurationProps,
} from '../../provider';

import { type PeopleAndTeamsMenuConfiguration } from './types';

const KEY = 'people-menu' as const;

export type WithMandatoryPeopleMenuConfigurationProps =
	WithMandatoryPeopleAndTeamsConfigurationProps<typeof KEY>;

export const usePeopleAndTeamsPeopleMenuConfiguration =
	(): PeopleAndTeamsMenuConfiguration | null => {
		const configuration = usePeopleAndTeamsConfigurationContext();

		return configuration[KEY];
	};

export const withMandatoryPeopleMenuConfiguration = <Props>(
	WrappedComponent: ComponentType<Props & WithMandatoryPeopleMenuConfigurationProps>,
) => withMandatoryPeopleAndTeamsConfiguration<typeof KEY, Props>(WrappedComponent, KEY);
