import { type ArtifactMetadata } from '@atlassiansox/flight-deck-frontend-client';

import { isFedRamp } from '@atlaskit/atlassian-context';

export const ARTIFACT_NAME = 'people-and-teams-configuration-service';
export const FD_ENVIRONMENT = 'prod';
export const FEDRAMP_PERIMETER = 'fedramp-moderate';
export const COMMERCIAL_PERIMETER = 'commercial';

export const FD_CLIENT_FETCH_PROPS: ArtifactMetadata = {
	name: ARTIFACT_NAME,
	dimensions: {
		environmentType: FD_ENVIRONMENT,
		perimeter: isFedRamp() ? FEDRAMP_PERIMETER : COMMERCIAL_PERIMETER,
	},
};
