import type { FC, MouseEvent, KeyboardEvent, ReactNode } from 'react';
import React, { useContext, memo, Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import Tooltip from '@atlaskit/tooltip/Tooltip';
import ButtonItem from '@atlaskit/menu/button-item';
import { PrimaryButton, useOverflowStatus } from '@atlaskit/atlassian-navigation';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import type { Route } from '@confluence/route';
import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	useKeyboardShortcutsState,
} from '@confluence/shortcuts';
import { AppNavigationContext } from '@confluence/app-navigation-context';

import { PrimaryItemWrapper } from '../presentationalComponents';

export type PrimaryItemProps = {
	testId?: string;
	onClick?: (event: MouseEvent | KeyboardEvent) => void;
	i18n?: {
		id: string;
		description?: string;
		defaultMessage?: string;
	};
	label?: ReactNode;
	namedRoute?: Route;
	href?: string;
	keyboardShortcut?: string;
	isAppNavigationShortcut?: boolean;
	isHighlighted?: boolean;
	pendoId?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PrimaryButtonWrapper = styled.div<{ visitedcolor?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& a:visited': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => (props.visitedcolor ? props.visitedcolor : 'auto'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		height: '100%',
	},
});

export const PrimaryItem: FC<PrimaryItemProps> = memo(
	({
		testId,
		i18n,
		label,
		namedRoute,
		onClick,
		href,
		keyboardShortcut,
		isHighlighted,
		isAppNavigationShortcut,
		pendoId,
	}) => {
		const { push, location, open } = useContext(RoutesContext);
		const { isVisible } = useOverflowStatus();
		const { theme } = useContext(AppNavigationContext);
		const [areKeyboardShortcutsEnabled] = useKeyboardShortcutsState();

		const url = href || (namedRoute && namedRoute.toUrl()) || '';

		const clickCallback = useCallback(
			(e: MouseEvent | KeyboardEvent) => {
				onClick && onClick(e);
				e.preventDefault();
				if (e.metaKey) {
					url && open(url, '_blank', 'noreferrer');
				} else {
					url && push(url);
				}
			},
			[open, push, onClick, url],
		);

		const buttonProps = {
			onClick: clickCallback,
			href: url,
			isHighlighted:
				isHighlighted !== undefined ? isHighlighted : !!location && url === location.pathname,
		};

		const content = (
			<span data-testid={testId} data-pendo-id={pendoId}>
				{label || <FormattedMessage {...i18n!} />}
			</span>
		);

		const expandedButton = (
			<PrimaryButtonWrapper visitedcolor={theme && theme.mode.primaryButton.default.color}>
				{!!keyboardShortcut && areKeyboardShortcutsEnabled ? (
					<PrimaryButton {...buttonProps}>
						<Tooltip content={<ShortcutVisualizer shortcut={keyboardShortcut} />}>
							{content}
						</Tooltip>
					</PrimaryButton>
				) : (
					<PrimaryButton {...buttonProps}>{content}</PrimaryButton>
				)}
			</PrimaryButtonWrapper>
		);

		const collapsedButton = (
			<PrimaryItemWrapper>
				<ButtonItem onClick={clickCallback}>{content}</ButtonItem>
			</PrimaryItemWrapper>
		);

		const onShortcutPressed = () => {
			url && push(url);
		};

		return (
			<Fragment>
				{isVisible ? expandedButton : collapsedButton}
				{keyboardShortcut && (
					<GeneralShortcutListener
						accelerator={keyboardShortcut}
						listener={onShortcutPressed}
						isAppNavigationShortcut={isAppNavigationShortcut || false}
					/>
				)}
			</Fragment>
		);
	},
);
