import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useRouteName } from '@confluence/route-manager';
import { WIKI, HOME } from '@confluence/named-routes';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from './homeOnboardingTakeoverV2OnboardingStateKeys';

export const useIsEligibleForHomeOnboardingTakeoverV2HomeNudge = ({
	skip = false,
}: {
	skip?: boolean;
} = {}) => {
	const isOnHomeOrWikiRouteArgs = {
		selector: (routeName: string | undefined) => {
			if (!routeName) {
				return false;
			}
			return routeName === WIKI.name || routeName === HOME.name;
		},
	};
	const isOnHome = useRouteName(isOnHomeOrWikiRouteArgs);
	const [experiment, _] = UNSAFE_noExposureExp('cc_home_onboarding_takeover_v2_land');
	const isNotInExperimentGroup = experiment.get<string>('cohort', 'not-enrolled') !== 'experiment';

	const localSkip = skip || isOnHome || isNotInExperimentGroup;
	const { data, loading, error } = useGetOnboardingState(
		Object.values(HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS),
		localSkip,
	);

	const { hasSeenHomeNudge, takeoverSeenAt } = deserializeState(data);

	if (localSkip || loading || error) {
		return {
			isEligibleForHomeNudge: false,
			loading,
			error,
		};
	}

	return {
		isEligibleForHomeNudge: Boolean(!hasSeenHomeNudge && takeoverSeenAt),
		loading,
		error,
	};
};
