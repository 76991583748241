import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { APP_NAV_APPS_DROPDOWN_EXPERIENCE } from '@confluence/experience-tracker';
import { SSRMouseEventWrapper, SSR_NAV_APPS_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteDataRef } from '@confluence/route-manager';
import {
	WebItemLocation,
	SYSTEM_HEADER_LEFT,
	type WebItemLocationChildrenFn,
} from '@confluence/web-item-location';
import { LoadableLazy } from '@confluence/loadable';
import type { ForgeUIGlobalPageExtensionType } from '@confluence/forge-ui';
import { LazyForgeUIExtensions, FORGE_MODULE_GLOBAL_PAGE } from '@confluence/forge-ui';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useSessionData } from '@confluence/session-data';
import { useIsProductAdmin } from '@confluence/current-user';

import type { PrimaryItemProps } from '../PrimaryItem';
import { PrimaryDropdownItem } from '../PrimaryDropdownItem';

import { SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY, AUTOMATION_KEY } from './AppKeys';

const TOP_LEVEL_ITEMS = [SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY];

const i18n = defineMessages({
	appsButton: {
		id: 'app-navigation.apps.link',
		defaultMessage: 'Apps',
		description: 'Label for the apps dropdown',
	},
});

const AppsContentLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AppsContent" */ './AppsContent')).AppsContent,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireAppsClickedAnalytics,
);

export const AppsItem: FC<PrimaryItemProps> = memo(({ testId }: PrimaryItemProps) => {
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { edition } = useSessionData();
	const { isProductAdmin } = useIsProductAdmin();
	const isPremiumEdition = edition === 'PREMIUM';
	const onClick = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_APPS_BUTTON_METRIC}>
			<PrimaryDropdownItem
				testId={testId}
				collapsedTestId="app-navigation-collapsed-apps"
				i18n={i18n.appsButton}
				content={() => (
					<WebItemLocation
						key="top-level-addons-location"
						location={SYSTEM_HEADER_LEFT}
						notAllowedWebItems={[
							...TOP_LEVEL_ITEMS,
							(!isPremiumEdition || !isProductAdmin) && AUTOMATION_KEY,
						]}
						fetchPolicy="cache-first"
						// @ts-expect-error FIXME: redundant prop, should be removed
						scope="global"
					>
						{
							(({ webItems }) => (
								<LazyForgeUIExtensions
									moduleType={FORGE_MODULE_GLOBAL_PAGE}
									render={(forgeApps, loading, error) => (
										<AppsContentLoader
											// @ts-expect-error FIXME: one of the attributes that is expected down the component tree is not provided, and never was
											webItems={webItems}
											forge={{
												items:
													error || loading ? [] : (forgeApps as ForgeUIGlobalPageExtensionType[]),
												loading,
												error,
											}}
										/>
									)}
								/>
							)) as WebItemLocationChildrenFn
						}
					</WebItemLocation>
				)}
				onOpen={() => onClick(true)}
				onClose={() => onClick(false)}
				experienceName={APP_NAV_APPS_DROPDOWN_EXPERIENCE}
			/>
		</SSRMouseEventWrapper>
	);
});
