import React, { useMemo } from 'react';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useViewUserProfile } from '@confluence/global-operations';
import type { useWebItemLocationWebItemType } from '@confluence/web-item-location';
import { useIsCompanyHubEnabled } from '@confluence/company-hub-utils/entry-points/useIsCompanyHubEnabled';

import { SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY } from '../featureFlagKeys';

import { CompanyHubItem } from './CompanyHubItem';
import { HomeItem } from './HomeItem';
import { SpacesItem } from './SpacesItem';
import { PagesItem } from './PagesItem';
import { PeopleItem } from './PeopleItem';
import { AppsItem } from './Apps';
import { TemplatesItem } from './TemplatesItem';

const CustomHomepageItemLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CustomHomepageItem" */ './CustomHomepageItem'))
			.CustomHomepageItem,
});

type UsePrimaryItemsProps = {
	customHomepage: { homepageUri: string; homepageTitle: string };
	webItems: useWebItemLocationWebItemType[];
};

export const usePrimaryItems = ({ customHomepage, webItems }: UsePrimaryItemsProps) => {
	const { isLicensed, accessStatus } = useSessionData();
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();
	const { isCompanyHubEntryPointEnabled } = useIsCompanyHubEnabled();

	const isHomeHidden =
		!isLicensed ||
		accessStatus === AccessStatus.ANONYMOUS_ACCESS ||
		accessStatus === AccessStatus.UNLICENSED_AUTHENTICATED_ACCESS;

	const isSpacesHidden = !webItems.find((item) => item.completeKey === SPACE_DIRECTORY_KEY);
	const isPeopleHidden =
		!hasViewUserProfilePermission ||
		!webItems.find((item) => item.completeKey === PEOPLE_DIRECTORY_KEY);

	const memoCustomHomePageItem = useMemo(() => {
		return customHomepage &&
			!['/spa.action', '', 'homepageUri'].includes(customHomepage.homepageUri) ? (
			<CustomHomepageItemLoader
				key="custom-homepage"
				testId="app-navigation-primary-custom-home"
				href={customHomepage.homepageUri}
				label={customHomepage.homepageTitle}
			/>
		) : null;
	}, [customHomepage]);

	const memoPrimaryItems = useMemo(() => {
		return [
			memoCustomHomePageItem,
			isCompanyHubEntryPointEnabled ? <CompanyHubItem key="company-hub" /> : null,
			!isHomeHidden ? (
				<HomeItem key="home" testId="app-navigation-primary-home" pendoId="con-*/wiki/home-home" />
			) : null,
			isLicensed ? <PagesItem key="pages" testId="app-navigation-primary-pages" /> : null,
			!isSpacesHidden ? (
				<SpacesItem
					key="spaces"
					testId="app-navigation-primary-spaces"
					pendoId="con-*/wiki/home-spaces"
				/>
			) : null,
			!isPeopleHidden ? <PeopleItem key="people" testId="app-navigation-primary-people" /> : null,
			<AppsItem key="apps" testId="app-navigation-primary-apps" />,
			<TemplatesItem key="templates" testId="app-navigation-primary-templates" />,
		].filter((item) => item !== null);
	}, [
		memoCustomHomePageItem,
		isCompanyHubEntryPointEnabled,
		isHomeHidden,
		isLicensed,
		isSpacesHidden,
		isPeopleHidden,
	]);

	return useMemo(
		() => ({
			primaryItems: memoPrimaryItems,
		}),
		[memoPrimaryItems],
	);
};
